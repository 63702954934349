<template>
  <base-popup
      :popup-model="show"
      title="Invoice Mail Template"
      :valid="!valid"
      :width="55"
      :show-save-button="false"
      @onCancelButtonClick="closeMailPopupHandler"
  >
    <GOverlay :is-relay="isOverlay"/>
    <v-card class="mt-5" ref="container">
      <v-card-actions>
        <v-spacer/>
        <v-btn
            depressed
            outlined
            @click="copyClipboard()"
        >
          Copy Text
        </v-btn>
      </v-card-actions>
      <v-card-text id="myInput" class="mail-text-weight">
        <p class="mail-text-black">
          We Have Published Your Article/Link.
        </p>
        <p v-if="currentItem.invoice" class="mail-text-black">Invoice Link: {{ getRefLink(currentItem.invoice.refcode) }}</p>
        <div v-if="currentItem.order">
          <p class="mail-text-black">Order Number: {{ currentItem.order.orderId }}</p>
          <p class="mail-text-black">{{getOrderLinkText()}}</p>
          <p class="mail-text-black" v-for="(item, key) in ordersLinks" :key="key">
            <a :href="item.liveLink">{{ item.liveLink }}</a> ${{ item.total }}
          </p>
        </div>
        <!--        <div v-if="currentItem.invoice">
                  <template v-for="(invoiceItem) in invoiceOrderLinks">
                    <p class="mail-text-black">Order Number: {{invoiceItem.sale_counter.orderId}}</p>
                    <p class="mail-text-black">Here Is Your Order's Live Link:</p>
                    <p class="mail-text-black" v-for="(item, key) in invoiceItem.sale_counter.sales_counter_details" :key="key">
                      <a
                          :href="item.liveLink">{{item.liveLink}}</a>
                      ${{item.total}}</p>
                  </template>
                </div>-->
      </v-card-text>
    </v-card>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import mailTemplate from "../../json/mailTemplate.json"
import GOverlay from "../GOverlay";

export default {
  name: "InvoiceMailExamplePopup",
  mixins: [Popup],
  components: {GOverlay, BasePopup},
  data() {
    return {
      selectedItem: 0,
      mailTemplate: mailTemplate,
      ordersLinks: [],
      invoiceOrderLinks: [],
      isOverlay: false,
    }
  },
  computed: {
    getLinks() {
      return this.currentItem.link ? this.currentItem.link.split('@') : [];
    }
  },
  watch: {
    currentItem: {
      handler: function (value) {
        if (Object.keys(value).length < 1) return;
        if (value.order) {
          this.getOrderLinks(value.order)
          //this.getInvoiceOrderLinks(value.invoice)
        }
      },
      immediate: true
    }
  },
  methods: {
    closeMailPopupHandler() {
      this.ordersLinks = [];
      this.invoiceOrderLinks = [];
      this.$emit('closeMailPopupHandler');
    },
    liveLinkHandler(site) {
      window.open(site, '_blank');
    },
    getRefLink(refcode) {
      const api = process.env.VUE_APP_BASE_URL;
      return `${api}/direct-payment?refno=${refcode}`;
    },
    async getInvoiceOrderLinks(invoice) {
      try {
        this.isOverlay = true;
        const {data} = await this.$store.dispatch('invoice/get', invoice);
        this.invoiceOrderLinks = data.data.item.invoice_detail;
        this.isOverlay = false;
      } catch (error) {
        this.isOverlay = false;
      }

    },
    async getOrderLinks(order) {
      try {
        this.isOverlay = true;
        const {data} = await this.$store.dispatch('salesCounter/counterDetails', order)
        this.ordersLinks = data.data.items;
        this.isOverlay = false;
      } catch (errors) {
        this.isOverlay = false;
      }
    },
    async copyClipboard() {
      let text = `We Have Published Your Article/Link.\n\n`;
      const paymentLink = this.getRefLink(this.currentItem.invoice.refcode);
      text+=`Invoice Link: ${paymentLink}\n\n`
      text += `Order Number:${this.currentItem.order.orderId}\n\n`
      text += `${this.getOrderLinkText()}\n`
      this.ordersLinks.forEach((order)=> {
        text += `${order.liveLink} $${order.total}\n`
      })

      try {
        await navigator.clipboard.writeText(text);
        console.log('Content copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    getOrderLinkText(){
      if (this.currentItem.order.billingType.toLowerCase() === ("Link Insertion on").toLowerCase()) return "Here Is Your Order's Link Insertion URL:";
      return "Here Is Your Order's Live Link:"
    }
  }
}
</script>

<style scoped lang="scss">
.mail-text-weight {
  font-weight: 500 !important;
}

.mail-text-black {
  color: #000 !important;
}
</style>