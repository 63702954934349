<template>
  <g-card page-title="Create a new order" page-icon="mdi-cart-outline" :back-button="backButton">
    <g-overlay :is-relay="isOverlay"/>
    <page-progress v-bind:is-loading="isLoading"/>
    <v-container class="pt-0" fluid>
      <v-row>
        <v-col cols="12" md="5" sm="12" xl="5" xs="12">
          <v-card>
            <v-card-text>
              <v-form ref="orderFormRef" v-model="orderValid" lazy-validation>
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="order.date"
                            label="Date"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                        />
                      </template>
                      <v-date-picker
                          v-model="order.date"
                          no-title
                          @input="menu1 = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="order.name"
                        dense
                        label="Name"
                        outlined
                    />
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                        v-if="!isExists"
                        v-model="order.email"
                        :rules="requiredEmailRule"
                        dense
                        :label="!showClientInnerIcon ? 'Email' : ''"
                        outlined
                        class="mr-2"
                        :prepend-inner-icon="showClientInnerIcon"
                        @change="fixClientHandler(order.email)"
                        append-icon="mdi-plus-box-outline"
                        @click:append="showClientPopupHandler"
                    />
                    <g-p-auto-complete
                        v-if="isExists"
                        :dense="true"
                        :outlined="true"
                        v-model="order.email"
                        :validation="true"
                        apiPath="sales-counter/get-all-email"
                        item-text="email"
                        item-value="email"
                        :search-label="!showClientInnerIcon ? 'Select a Email' : ''"
                        :inner-icon="showClientInnerIcon"
                        @clearSearchResult="handleClearSearchResultEmail"
                        @selectSearchResult="onSelectionsEmailHandler"
                        class="mr-2"
                        append-icon="mdi-plus-box-outline"
                        @onClickAppendIconHandler="showClientPopupHandler"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                        class="checkbox-mr-top"
                        v-model="isExists"
                        label="Is Exists?"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        v-model="order.billingType"
                        :items="['Guest Posting on', 'Link Insertion on', 'Home Page Link Insertion on', 'Article Writing and Publishing on', 'Software Development']"
                        :rules="requiredFieldRule"
                        label="Billing Type"
                        outlined
                        dense
                        @change="fixClientBillingTypeHandler"
                    />
                  </v-col>
                  <v-col cols="12" v-if="order.billingType === 'Home Page Link Insertion on'">
                    <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="order.linkExpiryDate"
                            label="Link Expiry Date"
                            dense
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        />
                      </template>
                      <v-date-picker
                          v-model="order.linkExpiryDate"
                          @change="saveOrderExpiryDate"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" v-if="orderDueInfo.length > 0">
                    <due-data-table :due-data="orderDueInfo"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" sm="12" xl="7" xs="12">
          <v-card>
            <v-card-text>
              <v-form ref="cartFormRef" v-model="formValid" lazy-validation>
                <v-row dense no-gutters>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <g-p-auto-complete
                        :dense="true"
                        :outlined="true"
                        :search-value="product.site"
                        :status=true
                        :validation="false"
                        apiPath="sites"
                        item-text="site_url"
                        search-label="Select a Site"
                        sort-by="site_url"
                        @clearSearchResult="handleClearSearchResult"
                        @selectSearchResult="onSelections"
                    />
                  </v-col>
                  <v-col cols="6" md="6" sm="12" xs="12">
                    <add-button
                        text="Add Site"
                        @onButtonClick="openSitePopup"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                        v-model="product.customEntityName"
                        label="Custom Entity Name"
                        class="mr-2"
                        outlined
                        dense
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="fixClient && Object.keys(fixClient).length > 0"
                        v-model="product.price"
                        :rules="priceRule"
                        class="right-align-text"
                        label="Price"
                        onclick="this.select()"
                        @input="inputPriceHandler()"
                        type="number"
                        outlined
                        dense
                    />
                  </v-col>
                  <v-col cols="12" md="12" v-if="order.billingType === 'Article Writing and Publishing on'">
                    <v-text-field
                        v-model="product.articleWritingPrice"
                        :rules="numberRule"
                        class="right-align-text"
                        label="Article Writing Price"
                        onclick="this.select()"
                        @input="inputPriceHandler()"
                        type="number"
                        outlined
                        dense
                    />
                  </v-col>
                  <template v-for="(item, key) in linkInsertions"
                            v-if="order.billingType === 'Link Insertion on' || order.billingType ==='Home Page Link Insertion on'">
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="item.anchorText"
                          :rules="requiredFieldRule"
                          label="Anchor Text"
                          class="mr-2"
                          outlined
                          dense
                      />
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-text-field
                          v-model="item.url"
                          :rules="requiredFieldRule"
                          label="URL"
                          class="mr-2"
                          outlined
                          dense
                      />
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-btn
                          v-if="key !==0"
                          color="error"
                          icon
                          @click="linkInsertionRowRemoveHandler(key)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                          v-if="linkInsertions.length === key + 1 && !(linkInsertions.length >= 10)"
                          color="primary"
                          fab
                          x-small
                          @click="linkInsertionRowHandler()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-textarea
                        v-model="product.liveLink"
                        label="Live Link"
                        :rules="requiredFieldRule"
                        outlined
                        dense
                        rows="2"
                        @change="checkLiveLinks()"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12" style="margin-top: -25px">
                    <v-checkbox
                        v-model="product.isAllowedLinkInsert"
                        label="Is allowed link insert ?"
                        color="indigo"
                        hide-details
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <v-checkbox
                        class="mb-5"
                        v-model="isCheckedAdultPrice"
                        :disabled="!product.siteId"
                        :label="`Other Post Link Price(+$${currentProduct.adultPrice ? currentProduct.adultPrice: '0.00'})`"
                        color="indigo"
                        hide-details
                        @change="onCheckboxAdultHandler()"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        v-model="product.total"
                        :rules="priceRule"
                        class="right-align-text"
                        dense
                        label="Total"
                        outlined
                        readonly
                        type="number"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <v-spacer/>
                <add-button
                    text="Add to Cart"
                    @onButtonClick="addToCartHandler"
                />
              </v-card-actions>
            </v-card-text>
          </v-card>
          <due-invoice-data-table
              v-show="invoiceDueInfo.length>0"
              :due-data="invoiceDueInfo"
              @selectedDueItemHandler="selectedDueInvoiceItemHandler"
          />
        </v-col>
        <v-col cols="12" md="6" sm="12" xl="6" xs="12">
          <v-card>
            <v-card-text>
              <v-checkbox v-model="isAddBulkLink" label="Bulk Link"/>
              <template v-if="isAddBulkLink">
                <v-checkbox
                    class="mb-5"
                    v-model="isBulkCheckedAdultPrice"
                    label="Other Post Link Price"
                    color="indigo"
                />
                <v-textarea
                    v-model="bulkLiveLink"
                    label="Bulk Link"
                    placeholder="Paste Live Link Here....."
                    outlined
                    dense
                    rows="4"
                    @input="bulkLiveLinkHandler($event)"
                />
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="12" xl="12" xs="12">
          <v-form ref="cartFooterForm" v-model="formValid" lazy-validation>
            <v-card>
              <v-data-table
                  :headers="headers"
                  :items="carts"
                  hide-default-footer
                  :disable-pagination="true"
              >
                <template v-slot:footer>
                  <v-divider/>
                  <v-toolbar flat>
                    <v-spacer/>
                    <cancel-button @onButtonClick="onCancelInvoice"/>
                    <confirm-button
                        text="Confirm Order"
                        :disabled="!carts.length && !order.isInvoice"
                        @onButtonClick="onConfirmHandler"
                    />
                  </v-toolbar>
                </template>
                <template v-slot:body.append>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Sub Total</h2>
                    </td>
                    <td style="text-align: right"><h2>${{ subTotal }}</h2></td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Discount</h2>
                    </td>
                    <td style="text-align: right">
                      <v-text-field
                          v-model="order.discount"
                          :rules="numberRule"
                          label="Discount"
                          type="number"
                          onclick="this.select()"
                          class="right-align-text discount-field mt-2 mb-1"
                          dense
                          outlined
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Grand Total</h2>
                    </td>
                    <td style="text-align: right"><h2>${{ grandTotal }}</h2></td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Order From</h2>
                    </td>
                    <td style="text-align: right">
                      <g-p-auto-complete
                          :dense="true"
                          :outlined="true"
                          :search-value="orderEmails"
                          :status=true
                          :validation="true"
                          apiPath="order-emails"
                          class="discount-field mt-2 mb-1"
                          @clearSearchResult="handleClearOrderEmailSearchResult"
                          @selectSearchResult="onSelectionsOrderEmail"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>Is Prepayment?</h2>
                    </td>
                    <td style="text-align: right">
                      <v-checkbox
                          v-model="order.isPrepayment"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr color="red">
                    <td colspan="8" style="text-align: right">
                      <h2>{{ isInvoiceText }}</h2>
                    </td>
                    <td style="text-align: right">
                      <v-checkbox
                          v-model="order.isInvoice"
                      />
                    </td>
                    <td></td>
                  </tr>
                </template>
                <template v-slot:top>
                  <v-toolbar dense flat>
                    Carts
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Cart is empty!
                </template>
                <template v-slot:item.liveLink={item}>
                  <v-chip
                      class="ma-2"
                      color="green"
                      link
                      text-color="white"
                      @click.prevent.stop="liveLinkHandler(item)"
                  >
                    View Live Link
                  </v-chip>
                </template>
                <template v-slot:item.isAllowedLinkInsert={item}>
                  <v-checkbox
                      v-model="item.isAllowedLinkInsert"
                      color="indigo"
                      hide-details
                  />
                </template>
                <template v-slot:item.linkInsertions={item}>
                  <v-chip
                      v-if="order.billingType === 'Link Insertion on' || order.billingType === 'Home Page Link Insertion on'"
                      class="ma-2"
                      color="lime"
                      link
                      text-color="white"
                      @click.prevent.stop="linkInsertionPopupHandler(item)"
                  >
                    View Links
                  </v-chip>
                </template>
                <template v-slot:item.price={item}>
                  {{ getAmountWithSymbol(item.price) }}
                </template>
                <template v-slot:item.adultPrice={item}>
                  {{ getAmountWithSymbol(item.adultPrice) }}
                </template>
                <template v-slot:item.articleWritingPrice={item}>
                  {{ getAmountWithSymbol(item.articleWritingPrice) }}
                </template>
                <template v-slot:item.total={item}>
                  {{ getAmountWithSymbol(item.total) }}
                </template>
                <template v-slot:item.actions={item}>
                  <v-btn
                      color="error"
                      icon
                      @click="confirmToDeleteItem(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
      <view-live-link
          :item="currentItem"
          :show="showLiveLink"
          @closePopupHandler="closePopupHandler"
          @onHandleLinkEditable="onHandleLinkEditable"
      />
      <link-insertions-popup
          :item="currentItem"
          :show="showLinkInsertionPopup"
          @closePopupHandler="closePopupHandler"
          @updateLinkInsertionHandler="updateLinkInsertionHandler"
      />
      <confirm-popup
          :show="showItemDeleteConfirm"
          confirm-event="onConfirmDelete"
          @closePopupHandler="showItemDeleteConfirm = false"
          @onConfirmDelete="confirmDelete()"
      >
        <span
        >Are you sure to remove
        <b>{{ this.deleteItem ? this.deleteItem.siteUrl : "" }}</b> from cart?</span
        >
      </confirm-popup>
      <confirm-popup
          :show="showOrderConfirm"
          confirm-event="onConfirmOrder"
          @closePopupHandler="showOrderConfirm = false"
          @onConfirmOrder="onConfirmHandler(true)"
      >
        <div class="py-3">
          <p class="text-xl-body-1">Are you sure to confirm the <b>order
            {{ order.isInvoice ? "and create a invoice" : "" }}
            {{ order.mergedInvoiceId ? `and merged order with invoice No:${selectedRow.invoiceNo}` : "" }}</b> from
            <b>{{ order.email && order.email ? order.email : " " }}</b> amount of
            <b>${{ grandTotal }}</b>?
          </p>
        </div>
      </confirm-popup>

      <confirm-popup
          :show="showInvoiceConfirm"
          confirm-event="onConfirmOrderInvoice"
          @closePopupHandler="showInvoiceConfirm = false"
          @onConfirmOrderInvoice="onConfirmOrderCreateInvoiceHandler(true)"
      >
        <div class="py-3">
          <p class="text-xl-body-1">Are you sure to confirm the <b>order and create a invoice</b> from
            <b>{{ order.email && order.email ? order.email : " " }}</b> amount of
            <b>${{ grandTotal }}</b>?
          </p>
        </div>
      </confirm-popup>
    </v-container>
    <site-popup
        :basic-data="basicData"
        :show="showSitePopup"
        @closePopupHandler="closeSitePopupHandler"
        @saveItemHandler="saveSiteItemHandler"
    />
    <InvoiceMailExamplePopup
        :show="showInvoiceEmailExample"
        :item="currentInvoice"
        @closeMailPopupHandler="closeInvoiceExamplePopupHandler"
    />
    <ClientPopup
        :show="showClientPopup"
        :basic-data="{
        price: 0,
        linkPrice: 0,
        adultPrice: 0,
        type: 0,
        discount: 0,
        isBlocked: 0,
      }"
        @closePopupHandler="closeClientPopupHandler"
        @saveItemHandler="saveClientItemHandler"
    />

    <confirm-popup
        :show="showWhoCreatedClient"
        @closePopupHandler="showWhoCreatedClient = false"
        :show-save-button="false"
    >
      <p class="text-xl-body-1">
        This client <strong>({{ order.email }})</strong> was created by <strong>{{ fixClient && fixClient.user ? fixClient.user.email: "" }}.</strong>
        Price: <strong>&nbsp;${{ fixClient ? fixClient.price: "" }}, </strong>
        Link Price: <strong>${{ fixClient ? fixClient.linkPrice: "" }}, </strong>
        Adult Price: <strong>${{ fixClient ? fixClient.adultPrice: "" }}, </strong>
        Discount: <strong>{{ fixClient ? fixClient.discount: "" }}%</strong>
      </p>
    </confirm-popup>
  </g-card>

</template>

<script>
import OrderMixins from "./mixins/OrderMixins";
import GOverlay from "./GOverlay";
import LinkInsertionsPopup from "./popups/LinkInsertionsPopup";
import DueDataTable from "./counters/DueDataTable";
import GCard from "./GCard";
import SitePopup from "./popups/SitePopup";
import Sites from "../services/Sites";
import InvoiceMailExamplePopup from "./popups/InvoiceMailExamplePopup";
import DueInvoiceDataTable from "./counters/DueInvoiceDataTable";
import ClientPopup from "./popups/ClientPopup";

export default {
  name: "GSalesCounter",
  components: {
    ClientPopup,
    DueInvoiceDataTable,
    InvoiceMailExamplePopup,
    SitePopup,
    GCard,
    DueDataTable,
    LinkInsertionsPopup,
    GOverlay
  },
  mixins: [OrderMixins],
  data() {
    return {
      backButton: {
        text: "Back to Reseller Order List",
        icon: "mdi-keyboard-backspace",
        name: "SaleCounters",
        path: "/sale-counters",
      },
      showSitePopup: false,
      basicData: {
        pa: 0,
        da: 0,
        dr: 0,
        ur: 0,
        traffic: 0,
        backLinks: 0,
        adminPrice: 0,
        price: 0,
        reseller_price: 0,
        linkPrice: 0,
        adultPrice: 0,
        follow: true,
        is_active: true,
        isCheckBrokenLink: true,
        google_news: true,
        casino: true,
        nichecategories: []
      },
    }
  },
  computed: {
    isInvoiceText() {
      if (this.order.mergedInvoiceId) return "Do you want to merge order with invoice?";
      return "Do you want to create invoice?";
    }
  },
  methods: {
    openSitePopup() {
      this.showSitePopup = true;
    },
    closeSitePopupHandler() {
      this.showSitePopup = false;
    },
    saveSiteItemHandler(item) {
      Sites.store(item)
          .then(({data: {message}}) => {
            this.setSnackBar({text: message, type: "info"});
          })
          .catch(({response: {data}}) => {
            this.setSnackBar({
              text: data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.showSitePopup = false;
          })
    },
    closeInvoiceExamplePopupHandler() {
      this.showInvoiceEmailExample = false;
      this.currentInvoice = {};
    },
    selectedDueInvoiceItemHandler(item) {
      if (item) {
        this.order.mergedInvoiceId = item.id
        this.selectedRow = item;
      } else {
        this.order.mergedInvoiceId = null
        this.selectedRow = null;
      }
    },
    showClientPopupHandler() {
      this.showClientPopup = true;
    },
    closeClientPopupHandler() {
      this.showClientPopup = false;
    },
    async saveClientItemHandler(payload) {
      try {
        const {data} = await this.$store.dispatch('client/store', payload);
        this.$store.commit("SET_SNACKBAR", {text: data.message, type: 'info'});
      } catch (errors) {
        this.$store.commit("SET_SNACKBAR", {text: errors.response.data.message, type: 'error'});
      }
    }
  }
}
</script>

<style lang="scss">
.price-field .v-input {
  max-width: 200px;
  float: right;
  align-items: center;
}

.discount-field .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
  display: none;
}

.checkbox-mr-top {
  margin-top: 2px !important;
}

.v-chip.v-size--default {
  height: 40px !important;
}
</style>