<template>
  <v-data-table
      :headers="headers"
      :items="dueData"
      :items-per-page="5"
      show-select
      :single-select="true"
      class="elevation-1"
      @item-selected="selectedItemHandler($event)"
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        Due Invoices(If you want to merge select one.)
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Due table is empty!
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ getDate(item.createdAt) }}
    </template>
    <template v-slot:item.totalAmount="{ item }">
      ${{ item.totalAmount }}
    </template>
  </v-data-table>
</template>

<script>
import GPaymentStatusChip from "../GPaymentStatusChip";
import DateHelper from "../mixins/DateHelper";

export default {
  name: "DueInvoiceDataTable",
  components: {GPaymentStatusChip},
  mixins: [DateHelper],
  data() {
    return {
      selected: [],
      headers: [
        {
          text: 'InvoiceID',
          value: 'invoiceNo'
        },
        {
          text: 'Created At',
          value: 'createdAt'
        },
        {
          text: 'Removal Mail',
          value: 'removalMail'
        },
        {
          text: 'After Removal Mail',
          value: 'afterRemovalMail'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          align: 'right'
        },
      ]
    }
  },
  props: {
    dueData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    selectedItemHandler(event) {
      const item = event.value === true ? event.item : null;
      this.$emit('selectedDueItemHandler', item);
    }
  }
}
</script>

<style scoped>

</style>